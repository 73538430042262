import React, { useEffect, useRef } from "react"
import Slider from "react-slick";
import { captureEvent } from 'event-service/modules/capture';
import style from "../vdp.module.scss"

import unavailable from "../../img/slide-01.jpg"

export default function PrimarySlider(props) {
  const slider = useRef(null)

  const { ListOfPhotos, unavailableImg } = props
  const { slide, setSlide } = props

  const handleImgError = (event) => {
    event.target.onerror = null;
    event.target.src = unavailableImg
  }

  useEffect(() => {
    (ListOfPhotos.length > 1) && slider.current.slickGoTo(slide)
  }, [slide])

  const settings = {
    arrows: false,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (old, idx) => {
      captureEvent({
        event: 'asc_media_interaction',
        event_action_result: 'click',
        affiliation: '',
        media_type: 'image',
        link_url: window.location.href,
        creative_name: '',
        item_id: document.querySelector('data[id="vin"]')?.value || '',
        item_number: document.querySelector('data[id="stock"]')?.value || '',
        item_price: document.querySelector('data[id="price"]')?.value || '',
        item_condition: document.querySelector('data[id="condition"]')?.value || '',
        item_year: document.querySelector('data[id="year"]')?.value || '',
        item_make: document.querySelector('data[id="make"]')?.value || '',
        item_model: document.querySelector('data[id="model"]')?.value || '',
        item_variant: document.querySelector('data[id="trim"]')?.value || '',
        item_color: document.querySelector('data[id="color"]')?.value || '',
        item_type: document.querySelector('data[id="type"]')?.value || '',
        item_category: document.querySelector('data[id="category"]')?.value || '',
        item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
        item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
        item_results: document.querySelector('data[id="results"]')?.value || '',
      }, {
        handlers: ['ASC']
      }).then(res => console.log(res)).catch(err => console.log(err))
      setSlide(idx)
    }
  }

  return (
    <>
      {(ListOfPhotos.length < 2)
        ?
        (ListOfPhotos.length === 0)
          ?
          <img className={style["main-image"]}
            alt=""
            src={unavailableImg} />
          :
          <img className={style["main-image"]}
            alt=""
            src={ListOfPhotos[0].PhotoUrl}
            onError={(e) => handleImgError(e)} />

        :
        <Slider {...settings} ref={slider}>
          {
            ListOfPhotos.map((slide, idx) => (
              <img className={style["main-image"]}
                alt=""
                src={slide.PhotoUrl}
                onError={(e) => handleImgError(e)} />
            ))
          }
        </Slider>
      }
    </>
  )
}

PrimarySlider.defaultProps = {
  ListOfPhotos: [],
  unavailableImg: unavailable
}