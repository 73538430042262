import React, { useContext } from "react"
import { Link } from "gatsby"
import { captureEvent } from 'event-service/modules/capture';
import { ModalVehicleContext } from "../modal/storage/VehicleContext"
import FinanceCalculator from "../../Modules/financeCalculator"

import style from "./vdp.module.scss"
export default function VDPFinancing(props) {
  const { Pricing } = props.vehicle

  const { months, downpayment, rates } = props.financing
  const { buttons, fallbackText } = props

  const [topRate] = rates
  const price = Number(Pricing.ExtraPrice1 || Pricing.List)

  const calc = new FinanceCalculator(price)
  const monthly = calc.calculateMonthlyPayment(
    price, calc.discount, calc.rebate, months, topRate, downpayment
  )

  const [, setFinanceModalContext] = useContext(ModalVehicleContext)
  const contextualizeItem = () => {
    setFinanceModalContext({ finalPrice: price, vehicleInfo: props.vehicle.VehicleInfo })
  }

  const setStyle = (button) => {
    if (button.hasOwnProperty("overrideTheme") && button.overrideTheme == true) {
      const styles = [
        button.hasOwnProperty("btncolor") && ["backgroundColor", button.btncolor],
        button.hasOwnProperty("btntxtcolor") && ["color", button.btntxtcolor]
      ].filter(el => el != false)
      return Object.fromEntries(styles)
    }
  }

  return (
    <>
      <div className={style["normal-vdpf"]}>
        <section className={style["details"]}>
          <div className={style["details__inner"]}>
            <ul className={style["offers"]}>
              <li className={style["offer"]}>
                <p className={style["offer-name"]}>
                  <button className={style["offer-btn"]}>Dealer Price</button>
                </p>
                <p className={style["offer-value"]}>
                  {price > 0 ? `$${FinanceCalculator.formatLocale(price)}` : fallbackText}
                </p>
              </li>
              {
                price > 0 && (
                  <li className={style["offer"]}>
                    <p className={style["offer-name"]}>
                      <button className={style["offer-btn"]}>Finance For</button>
                    </p>
                    <p className={style["offer-value"]}>
                      <span className={style["offer-btn offer-btn_underline"]}>
                        {`$${FinanceCalculator.formatLocale(monthly)}/Mo`}
                      </span>
                    </p>
                  </li>
                )
              }
            </ul>

            <div className={style["controls"]}>
              {buttons.map(button => (
                (button.buttonLink.includes('tel:')
                  ?
                  <a className={style["control-a-wrap"]} href={button.buttonLink}>
                    <button className={style["control"]} style={setStyle(button)}
                                              onClick={event => {
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                          contextualizeItem()
                        }}
                      >
                      {
                        (button.buttonImage && button.buttonImage.length > 0) ? (
                          <img src={button.buttonImage} style={{ height: "100%" }} />
                        ) : button.buttonText
                      }
                    </button>
                  </a>
                  :
                  <Link className={style["control-a-wrap"]} to={button.buttonLink}>
                    <button className={style["control"]} style={setStyle(button)}
                                              onClick={event => {
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                          contextualizeItem()
                        }}
                      >
                      {
                        (button.buttonImage && button.buttonImage.length > 0) ? (
                          <img src={button.buttonImage} style={{ height: "100%" }} />
                        ) : button.buttonText
                      }
                    </button>
                  </Link>
                )
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

VDPFinancing.defaultProps = {
  financing: {
    months: 0, rate: 0, downpayment: 0
  }
}
